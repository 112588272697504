export const siteData = {
  "name": "TARODAL",
  "address": "23 RUE PAUL BERT",
  "postCode": "06000",
  "city": "NICE",
  "phone": "0653985541",
  "url": "https://tarodal.pro",
  "urlSub": "tarodal.pro",
  "price": "price_1QS3ZbKkKc3BCKX0bCOuBlyI",
  "pk": "pk_live_51QS3NVKkKc3BCKX0rCl8t5pQgeCwE7179dUEY4iBiQC1JcFkOYurediJ39UB9ywuYsL3WUOiyBzt7XyxIaw2U0lL00qJvItaTZ",
  "sk": "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"
};